import { createRequestUrl } from '../utils/request-url-helper';
export const ShowApiPath = '/shows';
export const loadShows = async (axios, onlyActiveShows) => {
  const requestUrl = createRequestUrl(ShowApiPath, {
    onlyActiveShows
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};